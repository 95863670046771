<script setup>
import { ref } from "vue";
import ChipAppuntamento from "./ChipAppuntamento.vue";
import PrenotaAppuntamento from "./PrenotaAppuntamento.vue";
import MasterLayout from "../../../components/MasterLayout.vue";

const prenotaAppuntamentoVisible = ref(false);
const currentDate = ref(new Date());
const currentMentorAvatar = ref(null);

const users = [
  {
    name: "John Doe",
    image: "https://randomuser.me/api/portraits/men/2.jpg",
    selected: true,
  },
  {
    name: "Jane Doe",
    image: "https://randomuser.me/api/portraits/women/3.jpg",
    selected: true,
  },
  {
    name: "John Smith",
    image: "https://randomuser.me/api/portraits/men/3.jpg",
    selected: true,
  },
  {
    name: "Alice Johnson",
    image: "https://randomuser.me/api/portraits/women/5.jpg",
    selected: true,
  },
  {
    name: "Bob Brown",
    image: "https://randomuser.me/api/portraits/men/5.jpg",
    selected: false,
  },
  {
    name: "Charlie Davis",
    image: "https://randomuser.me/api/portraits/men/2.jpg",
    selected: false,
  },
  {
    name: "Diana Evans",
    image: "https://randomuser.me/api/portraits/women/1.jpg",
    selected: false,
  },
  {
    name: "Evan Green",
    image: "https://randomuser.me/api/portraits/men/6.jpg",
    selected: false,
  },
  {
    name: "Fiona Harris",
    image: "https://randomuser.me/api/portraits/women/6.jpg",
    selected: false,
  },
  {
    name: "George White",
    image: "https://randomuser.me/api/portraits/men/9.jpg",
    selected: false,
  },
  {
    name: "Hannah Moore",
    image: "https://randomuser.me/api/portraits/women/3.jpg",
    selected: false,
  },
  {
    name: "Ian Martin",
    image: "https://randomuser.me/api/portraits/men/7.jpg",
    selected: false,
  },
  {
    name: "Jessica Lee",
    image: "https://randomuser.me/api/portraits/women/13.jpg",
    selected: false,
  },
  {
    name: "Kevin Clark",
    image: "https://randomuser.me/api/portraits/men/9.jpg",
    selected: false,
  },
  {
    name: "Lily Walker",
    image: "https://randomuser.me/api/portraits/women/2.jpg",
    selected: false,
  },
  {
    name: "Michael Young",
    image: "https://randomuser.me/api/portraits/men/10.jpg",
    selected: false,
  },
  {
    name: "Natalie King",
    image: "https://randomuser.me/api/portraits/women/10.jpg",
    selected: false,
  },
  {
    name: "Oscar Hill",
    image: "https://randomuser.me/api/portraits/men/11.jpg",
    selected: false,
  },
  {
    name: "Paula Scott",
    image: "https://randomuser.me/api/portraits/women/11.jpg",
    selected: false,
  },
  {
    name: "Quinn Adams",
    image: "https://randomuser.me/api/portraits/women/3.jpg",
    selected: false,
  },
];

// Generiamo 11 appuntamenti con dati casuali e poi li ordiniamo
const appointments = Array.from({ length: 11 }, () => {
  const user = users[Math.floor(Math.random() * users.length)];

  // Data casuale nei prossimi 10 giorni
  const randomDate = new Date(
    Date.now() + Math.floor(Math.random() * 10) * 24 * 60 * 60 * 1000
  );

  // Ora casuale tra le 10:00 AM e le 4:00 PM
  const randomHour = 10 + Math.floor(Math.random() * 7); // 10 AM to 4 PM (10 + [0-6])
  const randomMinutes = Math.floor(Math.random() * 60); // Minuti casuali tra 0 e 59

  randomDate.setHours(randomHour, randomMinutes, 0, 0); // Settiamo l'ora casuale

  const confermato = true;

  return {
    avatarMentor: user.image,
    date: randomDate.toISOString(), // ISO string include anche l'orario
    confermato,
  };
});

// Ordinamento in base alla data (cronologico crescente)
appointments.sort((a, b) => new Date(a.date) - new Date(b.date));

const onClickChipAppuntamento = (appuntamento) => {
  prenotaAppuntamentoVisible.value = false;

  currentDate.value = appuntamento.date;
  currentMentorAvatar.value = appuntamento.avatarMentor;

  prenotaAppuntamentoVisible.value = true;
};
</script>

<template>
  <master-layout smallTitle="I tuoi incontri per" bigTitle="L'Induction Plan">
    <div class="bg-primary border-radius-client">
      <div class="px-2 py-4">
        <div class="my-4 w-full flex justify-content-center align-items-center">
          <span class="text-14" style="color: var(--text-color-light)">
            Tocca una data per vedere o modificare i dettagli
          </span>
        </div>

        <div class="grid">
          <ChipAppuntamento
            v-for="(appuntamento, index) in appointments"
            :key="index"
            :avatarMentor="appuntamento.avatarMentor"
            :date="appuntamento.date"
            :confermato="appuntamento.confermato"
            @click="onClickChipAppuntamento(appuntamento)"
          />
        </div>

        <div
          v-if="prenotaAppuntamentoVisible"
          class="w-full flex justify-content-center align-items-center mt-4 px-2"
        >
          <PrenotaAppuntamento
            :currentMentorAvatar="currentMentorAvatar"
            :currentDate="currentDate"
            title="Vuoi riprogrammare un'altra data?"
          />
        </div>
      </div>
    </div>
  </master-layout>
</template>

<style scoped>
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  padding: 1rem;
}
</style>
